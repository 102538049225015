.form_upload_input {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
}

.form_upload_input label {
  display: flex;
  gap: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form_upload_input label span {
  color: #3e236e;
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
  cursor: pointer;
}

.form_upload_input input[type="file"] {
  display: none;
}
.form_upload_input .image_preview_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form_upload_input .image_preview {
  display: flex;
  gap: 10px;
  align-items: center;
}

.form_upload_input .image_preview div {
  height: 58px;
  width: 65px;
}

.form_upload_input .image_preview img {
  height: 100%;
  width: 100%;
}
.form_upload_input .image_preview p {
  color: #3e236e;
  font-weight: 700;
  font-family: "Oxygen", sans-serif;
  font-size: 17px;
}
