.form_input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.form_input_container label {
  font-size: 16px;
  font-weight: 700;
  color: #181725;
  margin-bottom: 2px !important;
}

.form_input_container input {
  border: none;
  border-bottom: 1px solid #c4c4c4;
  outline: none;
  padding: 10px;
  background: transparent;
  
}
.form_input_container input:focus {
  border-bottom: 1px solid #181725;
}
