@-webkit-keyframes pulsate {
    0% {
      transform: scale(0.75); }
    50% {
      transform: scale(1.75); }
    100% {
      transform: scale(0.75); } }
  
  @-moz-keyframes pulsate {
    0% {
      transform: scale(0.75); }
    50% {
      transform: scale(1.75); }
    100% {
      transform: scale(0.75); } }
  
  @-o-keyframes pulsate {
    0% {
      transform: scale(0.75); }
    50% {
      transform: scale(1.75); }
    100% {
      transform: scale(0.75); } }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.75); }
    50% {
      transform: scale(1.75); }
    100% {
      transform: scale(0.75); } }
  
  @-webkit-keyframes rotate {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  
  @-moz-keyframes rotate {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  
  @-o-keyframes rotate {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  
  .payment-loader-container {
    margin: 5vh auto 0;
  
  }
    .payment-loader-container .payment-loader {
      width: 125px;
      height: 125px;
      margin: 0 auto; }
      .payment-loader-container .payment-loader .payment-circle {
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 5px solid #3E236E; }
        .payment-loader-container .payment-loader .payment-circle .payment-inner-circle {
          position: relative;
          left: -12.5%;
          top: 35%;
          width: 125%;
          height: 25%;
          background-color: white;
          -webkit-animation: rotate 2s infinite linear;
          animation: rotate 2s infinite linear; }
        .payment-loader-container .payment-loader .payment-circle h3 {
          position: relative;
          color: #000;
          top: -.25em;
          font-family: 'Oxygen';
          -webkit-animation: pulsate 1.25s infinite ease;
          animation: pulsate 1.25s infinite ease; }
  